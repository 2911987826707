import React, { useState } from "react";
import Modal from "react-modal";
import { db } from "./firebase"; // Import Firestore
import { doc, updateDoc, arrayUnion, getDoc, setDoc } from "firebase/firestore"; // Import Firestore functions
import { useAuth } from "./AuthContext"; // Import your Auth context

function FeatureRequest({ isOpen, setIsOpen }) {
  const [text, setText] = useState("");
  const { currentUser } = useAuth();

  const handleSend = async () => {
    if (text.trim() === "") return;

    const userFeatureRef = doc(db, "features", currentUser.uid);

    try {
      const docSnap = await getDoc(userFeatureRef);
      if (!docSnap.exists()) {
        await setDoc(userFeatureRef, {
          requests: [],
        });
      }

      await updateDoc(userFeatureRef, {
        requests: arrayUnion({
          text,
          createdAt: new Date(),
        }),
      });

      setText("");
      alert("Feature request submitted successfully!");
      setIsOpen(false);
    } catch (error) {
      console.error("Error submitting feature request: ", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          borderRadius: "10px",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <h2 style={{ marginBottom: "20px", fontSize: "24px", color: "#333" }}>
        Submit Feature Request
      </h2>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Describe your feature request..."
        required
        style={{
          width: "100%",
          height: "100px",
          padding: "10px",
          marginBottom: "20px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          fontSize: "16px",
          boxSizing: "border-box",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <button
          onClick={handleSend}
          style={{
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#28a745",
            color: "white",
            cursor: "pointer",
            fontSize: "16px",
            marginRight: "10px",
            flex: 1,
          }}
        >
          Send
        </button>
        <button
          onClick={() => setIsOpen(false)}
          style={{
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#dc3545",
            color: "white",
            cursor: "pointer",
            fontSize: "16px",
            flex: 1,
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

export default FeatureRequest;
