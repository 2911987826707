// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAUAHMQfD9RNyIW42YA5jfhOaw2hkOpp5o",

  authDomain: "docutranslate-41082.firebaseapp.com",

  projectId: "docutranslate-41082",

  storageBucket: "docutranslate-41082.appspot.com",

  messagingSenderId: "776734347070",

  appId: "1:776734347070:web:63220c89d5dea9572ee66f",

  measurementId: "G-WMY8LV47HL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, provider, signInWithPopup, app, db };
