import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { useAuth } from "./AuthContext"; // Import your Auth context
import { db } from "./firebase"; // Import Firestore
import { doc, onSnapshot } from "firebase/firestore"; // Import Firestore functions
import PricingModal from "./components/pricing/PricingModal";
import FeatureRequest from "./FeatureRequst";
import { Button } from "antd";
import { notification } from "antd"; // Import notification from antd

function App() {
  const { currentUser, logout } = useAuth(); // Get the current user and logout method from Auth context
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState("EN");
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(0); // Initialize credits to 0
  const [isOpen, setIsOpen] = useState(false);
  const [feature, setFeature] = useState(false);

  useEffect(() => {
    // Observe user credits from Firestore in real-time
    const fetchCredits = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const unsubscribe = onSnapshot(userRef, (userDoc) => {
          if (userDoc.exists()) {
            setCredits(userDoc.data().credits);
          }
        });
        return unsubscribe;
      }
      return () => {};
    };

    const unsubscribe = fetchCredits();

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [currentUser]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("language", language);
    formData.append("userId", currentUser.uid); // Send the user ID

    try {
      const idToken = await currentUser.getIdToken(); // Get the Firebase ID token
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/translate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Add the ID token in the Authorization header
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName =
        file.name.split(".").slice(0, -1).join(".") +
        `_translated_${language}.docx`;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error uploading the file: ", error);
      console.log(error.response.data);
      if (error.message.includes("501")) {
        window.alert(
          "Error: The initial language and the one you are translating in are the same"
        );
      } else if (error.message.includes("402")) {
        notification.error({
          message: "You don't have enough pages",
          description: "Try a file with less pages, or buy more pages",
        });
      } else if (error.message.includes("405")) {
        notification.error({
          message: "Invalid file type",
          description: "The only supported file types are PDF or images",
        });
      } else if (error.message.includes("406")) {
        notification.error({
          message: "Word files need to be converted to PDF",
          description: "Convert Word to PDF than upload the pdf",
        });
      } else {
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to login page or home page
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <div className="App">
      <PricingModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <FeatureRequest isOpen={feature} setIsOpen={setFeature} />
      <header className="App-header">
        <h1>DocuTranslate Dashboard</h1>

        <div style={{ display: "block" }}>
          <p>Available Pages: {credits}</p>
          <button
            className="logout-button"
            style={{ backgroundColor: "white", color: "black", width: "120%" }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Buy Pages
          </button>
        </div>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </header>
      <main>
        <div className="form-container">
          <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <label htmlFor="file">Choose a file:</label>
              <input
                type="file"
                id="file"
                onChange={handleFileChange}
                style={{ border: "1px solid grey" }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="language">
                Select the language you want to translate into:
              </label>
              <select
                id="language"
                value={language}
                onChange={handleLanguageChange}
                style={{ border: "1px solid grey" }}
              >
                <option value="BG">Bulgarian</option>
                <option value="CS">Czech</option>
                <option value="DA">Danish</option>
                <option value="DE">German</option>
                <option value="EL">Greek</option>
                <option value="EN-GB">English (UK)</option>
                <option value="EN-US">English (US)</option>
                <option value="ES">Spanish</option>
                <option value="ET">Estonian</option>
                <option value="FI">Finnish</option>
                <option value="FR">French</option>
                <option value="HU">Hungarian</option>
                <option value="ID">Indonesian</option>
                <option value="IT">Italian</option>
                <option value="JA">Japanese</option>
                <option value="KO">Korean</option>
                <option value="LT">Lithuanian</option>
                <option value="LV">Latvian</option>
                <option value="NB">Norwegian Bokmål</option>
                <option value="NL">Dutch</option>
                <option value="PL">Polish</option>
                <option value="PT-BR">Portuguese (Brazil)</option>
                <option value="PT-PT">Portuguese (Portugal)</option>
                <option value="RO">Romanian</option>
                <option value="RU">Russian</option>
                <option value="SK">Slovak</option>
                <option value="SL">Slovenian</option>
                <option value="SV">Swedish</option>
                <option value="TR">Turkish</option>
                <option value="UK">Ukrainian</option>
                <option value="ZH">Chinese</option>
                <option value="ZH-HANS">Chinese (Simplified)</option>

                {/* Add more language options as needed */}
              </select>
            </div>
            <p style={{ fontSize: "10px" }}>
              By using our service you are agreeing with our{" "}
              <a href="/terms.pdf" style={{ color: "blue" }}>
                terms and conditions
              </a>
              .
            </p>
            <button
              type="submit"
              className="submit-button"
              disabled={loading || credits <= 0}
              style={{ backgroundColor: "#6415ff" }}
            >
              Upload and Translate
            </button>
          </form>
          {loading && <p>Loading... Please wait.</p>}
        </div>
      </main>

      <Button
        onClick={() => {
          setFeature(true);
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          width: "fit-content",
        }}
      >
        Request a new feature
      </Button>
    </div>
  );
}

export default App;
