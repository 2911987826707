import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import Modal from "react-modal";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useAuth } from "AuthContext";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import axios from "axios";

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

export default ({
  isOpen,
  onRequestClose,
  subheading = "",
  setIsOpen,
  heading = "Buy Credits",
  description = "Choose the package that suits you best.",
  plans = null,
  primaryButtonText = "Get credits",
}) => {
  const { currentUser } = useAuth();
  const handleBuyCredits = async (credits) => {
    try {
      const idToken = await currentUser.getIdToken(); // Get the Firebase ID token
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        {
          userId: currentUser.uid,
          credits: credits,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // Add the ID token in the Authorization header
          },
        }
      );

      const { id } = response.data;
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE);
      await stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      console.error("Error creating Stripe checkout session: ", error);
      window.alert("Error creating Stripe checkout session: " + error.message);
    }
  };
  const defaultPlans = [
    {
      name: "Small",
      price: ["10 pages"],
      oldPrice: "$10",
      description: "You can translate 10 more pages with this package.",
      features: [],
      credits: 10,
    },
    {
      name: "Medium",
      price: ["50 pages"],
      oldPrice: "$35",
      description: "You can translate 50 more pages with this package.",
      features: [],
      url: "https://google.com",
      featured: "Most Popular",
      credits: 50,
    },
    {
      name: "Big",
      price: ["100 pages"],
      oldPrice: "$50",
      description: "You can translate 100 more pages with this package.",
      features: [],
      url: "https://google.com",
      credits: 100,
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      style={{ width: "100%", height: "100%", padding: 0 }}
    >
      <Container>
        <ContentWithPaddingXl>
          <HeaderContainer>
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContainer>
          <PlansContainer>
            {plans.map((plan, index) => (
              <Plan key={index} featured={plan.featured}>
                <PlanHeader>
                  <span className="nameAndFeaturedContainer">
                    <span className="name">{plan.name}</span>
                    {plan.featured && (
                      <span className="featuredText">{plan.featured}</span>
                    )}
                  </span>
                  <div className="pricingContainer">
                    <span className="currentPrice">
                      <span className="bigText">{plan.price[0]}</span>
                      {plan.price[1]}{" "}
                    </span>
                    {plan.oldPrice && (
                      <span style={{ fontSize: "20px" }}>{plan.oldPrice}</span>
                    )}
                  </div>
                  <p className="description">{plan.description}</p>
                </PlanHeader>

                <PlanAction>
                  <ActionButton
                    as="a"
                    onClick={() => {
                      handleBuyCredits(plan.credits);
                    }}
                  >
                    {primaryButtonText}
                  </ActionButton>
                </PlanAction>
              </Plan>
            ))}
          </PlansContainer>
        </ContentWithPaddingXl>
        <WhiteBackgroundOverlay />
      </Container>
    </Modal>
  );
};
